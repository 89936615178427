import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import TikTokButton from "../components/tiktokButton";
import { useEffect } from "react";

function Login() {

    const { isAuthenticated } = useAuth();
    const navigate = useNavigate()
    useEffect(() => {
        if (isAuthenticated) {
            fetch('/me', {
                method: 'GET'
            });
            navigate('/');
        }
    },[navigate, isAuthenticated]);

    return (
        <div>
            <h1>Login Page</h1>
            {!isAuthenticated && (
                <a href='/oauth'><TikTokButton /></a>
            )}

        </div>
    );
}

export default Login;