import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import TikTokButton from './tiktokButton';
import BreachLogo from '../assets/images/Breach_Logo.png';

function TopNav() {
  const { isAuthenticated } = useAuth();

  return (
    <Navbar expand="md" bg="dark" data-bs-theme="dark">
      <Container>
        <Navbar.Brand to="/">
          <img
            src={BreachLogo}
            width="30"
            height="30"
            alt="BreachMC Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/tos">Terms of Service</Nav.Link>
            <Nav.Link as={Link} to="/privacy">Privacy Policy</Nav.Link>
          </Nav>
          <Nav>
            {isAuthenticated ? (
              <Nav.Link href='/logout'>Logout</Nav.Link>
            ) : (
              <Nav.Link href='/oauth' ><TikTokButton /></Nav.Link>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNav;