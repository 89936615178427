import Container from "react-bootstrap/Container";
import { useAuth } from "../contexts/AuthContext";
import breachMC from "../assets/images/BreachMC.webp"
import UploadModal from "../components/uploadModal";
import { useState } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

function Home() {

    const { isAuthenticated } = useAuth();
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [userData, setUserData] = useState(null);

    const handleFileChange = (e) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleButtonClick = async (e) => {
        e.preventDefault();
        if (!file) {
            setMessages(...messages, { type: 'danger', text: 'No file selected' });
            return;
        }
        const response = await fetch('/me', {
            method: 'GET'
        });
        const data = await response.json();
        if (data.error.code !== "ok") {
            setMessages([...messages, { type: 'danger', text: data.error.message }]);
            return;
        }
        const video = document.createElement('video');
        const objectURL = URL.createObjectURL(file);

        video.preload = 'metadata';
        video.src = objectURL;

        const isVideoFileValid = await new Promise((resolve, reject) => {
            video.onloadedmetadata = function () {
                URL.revokeObjectURL(objectURL);
                const duration = video.duration;
                const maxPostDuration = data.data.max_video_post_duration_sec;
                console.log({ maxPostDuration, duration });
                if (duration > maxPostDuration) {
                    setMessages([...messages, { type: 'danger', text: 'File duration is too long' }]);
                    resolve(false);
                }
                resolve(true);
            };

            video.onerror = function () {
                console.error('Error loading video metadata.');
                setMessages([...messages, { type: 'danger', text: 'File failed to load' }]);
                resolve(false);
            };
        });
        console.log({ isVideoFileValid });
        if (!isVideoFileValid) return;
        setUserData(data.data);
        setIsUploading(true);
    }

    return (
        <Container>
            <h1 style={{ textAlign: "center" }}>Welcome To BreachMC!</h1>
            <picture>
                <img width={"100%"} src={breachMC} loading="lazy" alt="BreachMC Art" />
            </picture>
            <p style={{ fontSize: "28px" }}>
                BreachMC harnesses the power of AI to generate captivating and imaginative stories for you.
                With just a few clicks, you can create amazing content and share it with your audience on TikTok.
                Connect with your TikTok account to upload and showcase your unique stories, and let the world
                experience the magic you’ve created.
            </p>
            {messages.map((message) => {
                return (
                    <Alert onClose={() => setMessages([])} key={message.text} variant={message.type} dismissible>{message.text}</Alert>
                )
            })}
            {isAuthenticated && (
                <Form>
                    <Form.Group controlId="formFile" className="mb-3 custom-select">
                        <Form.Label>Video File</Form.Label>
                        <Form.Control type="file" accept=".mp4,.mov,.webm" onChange={handleFileChange} />
                    </Form.Group>
                    <Button variant="primary" disabled={!file} onClick={handleButtonClick}>Next</Button>
                </Form>
            )}
            {(isAuthenticated && file && isUploading) && <UploadModal setMessages={setMessages} userData={userData} file={file} setShowing={setIsUploading} />}
        </Container>
    );
}

export default Home;