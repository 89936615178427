import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import VideoPlayer from './videoPlayer';
import UploadForm from './uploadForm';

export default function UploadModal({ userData, file, setShowing, setMessages }) {


    return (
        <Modal show={true} onHide={() => setShowing(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Upload Video</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col>
                        <VideoPlayer file={file} />
                    </Col>
                    <Col>
                        <UploadForm userData={userData} file={file} showModal={setShowing} setMessages={setMessages}/>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

