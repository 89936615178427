import './App.css';
import TopNav from './components/topNav';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './styles.css'
import Login from './pages/Login';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Home from './pages/Home';

function App() {
  return (
    <Router>
      <div className="App">
      <TopNav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tos" element={<TermsOfService />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
    </Router>
  );
}

export default App;
