import ReactPlayer from "react-player";

function VideoPlayer({ file }) {

    return (
        <div
            style={{
                display: "flex", // Flexbox for centering
                justifyContent: "center", // Center horizontally
                alignItems: "center", // Center vertically
            }}
        >
            <div
                style={{
                    position: "relative",
                    width: "100%", // Let it scale with the container
                    paddingTop: "177.78%", // 9:16 aspect ratio
                }}
            >
                <ReactPlayer
                    url={URL.createObjectURL(file)}
                    playing={false}
                    controls
                    width="100%"
                    height="100%"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }}
                />
            </div>
        </div>
    )
}

export default VideoPlayer;