import { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/esm/Container';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'


function UploadForm({ userData, file, setMessages, showModal }) {
    const [title, setTitle] = useState('');
    const [privacyLevel, setPrivacyLevel] = useState(null);
    const [duetDisabled, setDuetDisabled] = useState(true);
    const [stitchDisabled, setStitchDisabled] = useState(true);
    const [commentDisabled, setCommentDisabled] = useState(true);
    const [isBrandedContent, setIsBrandedContent] = useState(false);
    const [isYourBrand, setIsYourBrand] = useState(false);
    const [isOtherBrand, setIsOtherBrand] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [isBrandToolTipShowing, setIsBrandToolTipShowing] = useState(false);
    const [isUploadToolTipShowing, setIsUploadToolTipShowing] = useState(false);

    const privacyMap = {
        "PUBLIC_TO_EVERYONE": "Public",
        "SELF_ONLY": "Private",
        "MUTUAL_FOLLOW_FRIENDS": "Friends Only",
        "FOLLOWER_OF_CREATOR": "Follower Only"
    }

    const privacyLevels = userData.privacy_level_options.map((level) => {
        return (
            <option title={isBrandedContent && level === "SELF_ONLY" ? "Branded content visibility cannot be set to private." : null} disabled={isBrandedContent && level === "SELF_ONLY"} key={level} value={level}>{privacyMap[level]}</option>
        )
    });

    const handleUploadClick = (e) => {
        e.preventDefault();
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('title', title);
        formData.append('privacyLevel', privacyLevel);
        formData.append('isAigc', false);
        formData.append('duetDisabled', duetDisabled);
        formData.append('stitchDisabled', stitchDisabled);
        formData.append('commentDisabled', commentDisabled);
        formData.append('isYourBrand', isYourBrand);
        formData.append('isOtherBrand', isOtherBrand);
        formData.append('contentLength', file.size);
        fetch('/upload', {
            method: 'POST',
            body: formData,
        })
            .then((response) => {
                console.log("response");
                return response.json();
            })
            .then((data) => {
                if (data.success) {
                    console.log('Successfull upload:', JSON.stringify(data, null, 2));
                    setMessages([{ type: 'info', text: 'Uploaded successfully!' }])
                } else {
                    console.log('Failed upload:', JSON.stringify(data, null, 2));
                    setMessages([{ type: 'danger', text: data?.message }]);
                }
                showModal(false);
            })
            .catch((error) => {
                console.log(error);
                console.error('Error:', error);
                setMessages([{ type: 'danger', text: 'Upload failed' }]);
            });
        setIsUploading(true);
    };

    return (
        <Container>
            <Form>
                <img src={userData.creator_avatar_url} alt="avatar" style={{ width: 50, height: 50, borderRadius: "50%", objectFit: "cover", display: "inline-block" }} />
                <p>{userData.creator_nickname}</p>
                <Form.Group>
                    <Form.Label>Caption</Form.Label>
                    <Form.Control maxLength={100} type="text" placeholder="Add a title that describes your video" value={title} onChange={(e) => setTitle(e.target.value)} />
                </Form.Group>
                <Form.Group>
                    <Form.Label>Who can view this video</Form.Label>
                    <Form.Control as="select" value={privacyLevel} onChange={(e) => setPrivacyLevel(e.target.value)}>
                        <option value="">Select Privacy Level</option>
                        {privacyLevels}
                    </Form.Control>
                </Form.Group>
                <Form.Label>Allow users to</Form.Label>
                <Form.Group>
                    <Row>
                        <Col>
                            <Form.Check type="checkbox" label="Comment" disabled={userData.comment_disabled} checked={!commentDisabled} onChange={(e) => setCommentDisabled(!e.target.checked)} />
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Duet" disabled={userData.duet_disabled} checked={!duetDisabled} onChange={(e) => setDuetDisabled(!e.target.checked)} />
                        </Col>
                        <Col>
                            <Form.Check type="checkbox" label="Stitch" disabled={userData.stitch_disabled} checked={!stitchDisabled} onChange={(e) => setStitchDisabled(!e.target.checked)} />
                        </Col>

                    </Row>
                </Form.Group>
                <Form.Group>
                    <OverlayTrigger trigger={"hover"} show={isBrandToolTipShowing} placement="bottom-start" onToggle={(nextShow) => setIsBrandToolTipShowing(privacyLevel === "SELF_ONLY" && nextShow)} overlay={<Tooltip>Visibility for branded content can't be private</Tooltip>}>
                        <span>
                            <Form.Check
                                className="custom-select"
                                type="switch"
                                id="branded-checkbox"
                                label="Disclose video content"
                                checked={isBrandedContent}
                                disabled={privacyLevel === "SELF_ONLY"}
                                onChange={(e) => {
                                    setIsBrandedContent(e.target.checked)
                                    if (!e.target.checked) {
                                        setIsYourBrand(false)
                                        setIsOtherBrand(false)
                                    }
                                }}
                            />
                        </span>
                    </OverlayTrigger>
                    {(isBrandedContent && isYourBrand && !isOtherBrand) && (
                        <Alert variant="info">Your video will be labeled "Promotional content". This cannot be changed once your video is posted</Alert>
                    )}
                    {(isBrandedContent && isOtherBrand) && (
                        <Alert variant="info">Your video will be labeled "Paid partnership". This cannot be changed once your video is posted</Alert>
                    )}
                    {(isBrandedContent && !isYourBrand && !isOtherBrand) && (
                        <Alert variant="warning">You must select at least one option</Alert>
                    )}
                    <p>Turn on to disclose that this video promotes goods or services in exchange for something of value. Your video could promote yourself, a thrid party, or both.</p>
                    {isBrandedContent && (
                        <>
                            <Form.Check type="checkbox" label="Your brand" checked={isYourBrand} onChange={(e) => setIsYourBrand(e.target.checked)} />
                            <p>You are promoting yourself or your own business. This video will be classified as Brand Organic</p>
                            <Form.Check type="checkbox" label="Branded Content" checked={isOtherBrand} onChange={(e) => setIsOtherBrand(e.target.checked)} />
                            <p>You are promoting another brand or a third party. This video will be classified as Branded Content</p>
                            <br />
                        </>
                    )}
                </Form.Group>
                {isOtherBrand ? (
                    <p>By posting, you agree to TikTok's <a href='https://www.tiktok.com/legal/page/global/bc-policy/en'>Branded Content Policy</a> and <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a></p>
                ) : (
                    <p>By posting, you agree to our <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en">Music Usage Confirmation</a></p>
                )}
                {isUploading ? (
                    <><Spinner animation="border" variant="primary" />Uploading</>
                ) : (
                    <OverlayTrigger show={isUploadToolTipShowing} onToggle={(nextShow) => setIsUploadToolTipShowing(nextShow && (!privacyLevel || (isBrandedContent && !isYourBrand && !isOtherBrand) || (isBrandedContent && privacyLevel === "SELF_ONLY")))} overlay={(<Tooltip>{!privacyLevel ? "Select Privacy Level" : isBrandedContent && !isYourBrand && !isOtherBrand ? "You need to indicate if your content promotes yourself, a third party, or both." : "Branded content can't be private"}</Tooltip>)}>
                        <span>
                            <Button disabled={!privacyLevel || (isBrandedContent && !isYourBrand && !isOtherBrand) || (isBrandedContent && privacyLevel === "SELF_ONLY")} variant="primary" type="submit" onClick={handleUploadClick}>
                                Upload
                            </Button>
                        </span>
                    </OverlayTrigger>
                )}
            </Form>
        </Container>
    )
}

export default UploadForm;