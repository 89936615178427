import React, { createContext, useState, useContext } from "react";

// Create a Context
const AuthContext = createContext();

function getCookie(key) {
    var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : "";
}

// Export a custom hook for convenience
export const useAuth = () => useContext(AuthContext);

// Create a Provider component
export const AuthProvider = ({ children }) => {

    const accessToken = getCookie("accessToken");
    const refreshToken = getCookie("refreshToken");
    console.log({accessToken});
    if (accessToken) {
        fetch('/me', {
            method: 'GET'
        })
    } else if (refreshToken) {
        fetch('/oauth', {
            method: 'GET',
        }).then(() => {
            fetch('/me', {
                method: 'GET'
            })
        })
    }

    const [isAuthenticated, setIsAuthenticated] = useState(Boolean(accessToken));
    const login = () => setIsAuthenticated(true);
    const logout = () => {
        setIsAuthenticated(false)
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
